import { render, staticRenderFns } from "./ChangeDirectiveDisplay.vue?vue&type=template&id=83bdee54&scoped=true&"
import script from "./ChangeDirectiveDisplay.vue?vue&type=script&lang=js&"
export * from "./ChangeDirectiveDisplay.vue?vue&type=script&lang=js&"
import style0 from "./ChangeDirectiveDisplay.vue?vue&type=style&index=0&id=83bdee54&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83bdee54",
  null
  
)

export default component.exports